<script>
import axios from "axios";
import {
  ResponseTimeKPI as ResponseTimeKPIModal,
  CompletionKPI as CompletionKPIModal,
  LeadsKPI as LeadsKPIModal,
  InquiresByStatus as InquiresByStatusModal,
} from "@/views/pages/statistics/modals";
import InquiryKPI from "@/components/kpis/InquiryKPI.vue";
import {INQUIRY_KPI} from "@/core/KPI";

import {h} from "vue"
import moment from "moment";

export default {
  name: 'InquiryStatistics',
  components: {
    ResponseTimeKPIModal,
    CompletionKPIModal,
    LeadsKPIModal,
    InquiresByStatusModal,
    InquiryKPI
  },
  data() {
    return {
      inquiries: [],
      is_loading: true,
      active_tab: 'kpi', // 'all' | 'kpi',
      response_time_kpi_bonus_percentage: 90,
      follow_up_kpi_bonus_percentage: 100,
      conversion_rate_kpi_bonus_percentage: 20,
      leads_generated_kpi_bonus_quantity: 30,
      INQUIRY_KPI: INQUIRY_KPI,
      showSalesKPIModal: false,

      kpis: [
        {
          name: 'Response Time KPI',
          access_key: 'response_time',
          onClick: (params) => this.$refs.responseTimeKPIModal.openModal(params),
          why: (item) => {
            return h('span', {class: 'd-flex gap-1 mt-1'}, [
              h('small', {
                class: 'badge border border-success text-success',
                'v-b-tooltip.hover': true,
                title: 'A: Quoted within 2 days',
                onClick: () => this.$refs.responseTimeKPIModal.openModal(item),
              }, item.response_time.details.A.count),
              h('small', {
                class: 'badge border border-warning text-warning',
                'v-b-tooltip.hover': true,
                title: 'B: Quoted within 3 days'
              }, item.response_time.details.B.count),
              h('small', {
                class: 'badge border border-danger text-danger',
                'v-b-tooltip.hover': true,
                title: 'C: Quoted after 3 days'
              }, item.response_time.details.C.count),
            ])
          },
        },
        {
          name: 'Follow-up KPI',
          access_key: 'completion',
          why: (item) => {
            return h('span', {class: 'd-flex gap-1'}, [
              h('small', {
                class: 'badge border border-success text-success',
                'v-b-tooltip.hover': true,
                title: 'A: Completed within 5 days'
              }, item.completion.details.A.count),
              h('small', {
                class: 'badge border border-warning text-warning',
                'v-b-tooltip.hover': true,
                title: 'B: Completed within 7 days'
              }, item.completion.details.B.count),
              h('small', {
                class: 'badge border border-danger text-danger',
                'v-b-tooltip.hover': true,
                title: 'C: Completed after 7 days'
              }, item.completion.details.C.count),
            ])
          }
        },
        {
          name: 'Conversion Rate KPI',
          access_key: 'conversion',
          why: (item) => {
            let total = item.total - item.statuses.find(i => i.label === 'Pending').value
            let success = item.statuses.find(i => i.label === 'Success').value || 0

            return h('span', {class: 'd-flex align-items-center gap-1'}, [
              h('i', {class: 'mdi mdi-information-outline'}),
              h('small', `${success}/${total} ≈ ${((success / (total || 1)) * 100).toFixed(1)}%`)
            ])
          }
        },
        {
          name: 'New Leads Generated',
          access_key: 'leads',
          why: (item) => {
            return h('span', {class: 'd-flex align-items-center gap-1'}, [
              h('i', {class: 'mdi mdi-information-outline'}),
              h('small', `${item.leads.actual}/5`)
            ])
          }
        }
      ]
    };
  },
  provide() {
    return {
      response_time_kpi_bonus_percentage: 90,
    };
  },
  computed: {
    total_inquires_count() {
      return this.inquiries.reduce((sum, inquiry) => sum + inquiry.total, 0)
    },
    total_successful_inquires_count() {
      return this.inquiries.reduce((sum, inquiry_item) => {
        const failStatus = inquiry_item.statuses.find(i => i.label === 'Success');
        const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
        return sum + failValue;
      }, 0)
    },
    total_pending_inquires() {
      return this.inquiries.reduce((sum, inquiry_item) => {
        const failStatus = inquiry_item.statuses.find(i => i.label === 'Pending');
        const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
        return sum + failValue;
      }, 0)
    },
    date_from() {
      return this.$route.query.start_date
    },
    date_to() {
      return this.$route.query.end_date
    },
  },
  methods: {
    async getInquiries() {
      this.inquiries = []
      this.is_loading = true;
      try {
        const {start_date, end_date} = this.$route.query;

        if (this.active_tab === 'kpi') {
          let response = await axios.get('/inquiry/statistic/kpi/', {
            params: (start_date && end_date) ? {
              date_from: start_date,
              date_to: end_date,
            } : {}
          });

          this.inquiries = (response.data || []).map((i) => {

            let total_inquiries = i.total
            let total_pending_inquiries = i.statuses.find(i => i.status === 'Pending').count || 0
            let total_success_inquiries = i.statuses.find(i => i.status === 'Success').count || 0

            const get_success_ratio = () => {
              try {
                return ((total_success_inquiries / ((total_inquiries - total_pending_inquiries) || 1)) * 100).toFixed(1)
              } catch {
                return 0
              }
            }

            const calculatePercentageToGetGreenKpi = (totalInquiries) => {

              const isBeforeMarch1 = moment(start_date).isBefore('2025-03-01', 'day')

              if (isBeforeMarch1) {
                return 80;
              }

              if (totalInquiries > 50) {
                return 75;
              } else if (totalInquiries >= 35 && totalInquiries <= 50) {
                return 80;
              } else {
                return 90;
              }
            }

            return {
              ...i,
              total_pending_inquiries: total_pending_inquiries,
              total_success_inquiries: total_success_inquiries,
              overall_grade_color: this.get_overall_grade_color(i.overall_grade),
              conversion_percentage: get_success_ratio(),
              statuses: i.statuses.map((item) => {
                return {
                  label: item.status,
                  value: item.count,
                  color: this.get_status_color(item.status),
                  inquiries: item.inquiries || []
                }
              }),
              overall_percentage: this.calculateOverallPerformance(i),
              percentage_to_get_green_kpi: calculatePercentageToGetGreenKpi(total_inquiries)
            }
          });

        } else if (this.active_tab === 'all') {
          let response = await axios.get('/inquiry/statistic/', {
            params: (start_date && end_date) ? {
              date_from: start_date,
              date_to: end_date,
            } : {}
          });

          this.inquiries = (response.data || []).map((i) => {

            let total_pending_inquiries = i.statuses.find(i => i.status === 'Pending').count || 0
            let total_success_inquiries = i.statuses.find(i => i.status === 'Success').count || 0

            return {
              ...i,
              total_pending_inquiries: total_pending_inquiries,
              total_success_inquiries: total_success_inquiries,
              statuses: i.statuses.map((item) => {
                return {
                  label: item.status,
                  value: item.count,
                  color: this.get_status_color(item.status),
                  inquiries: item.inquiries || []
                }
              }),
            }
          });
        }
        this.is_loading = false;
        await this.checkRouterQuery('auto')
      } catch {
        this.inquiries = [];
        this.is_loading = false;
        alert("Could not load inquiries by managers table");
      }
    },
    async checkRouterQuery() {
      let sales_manager_id = this.$route.query.sales_manager_id
      let kpi_modal = this.$route.query.kpi
      let filter_by_grade = this.$route.query.filter_by_grade
      let filter_by_status = this.$route.query.filter_by_status

      if (sales_manager_id && kpi_modal === 'response_time') {
        try {
          let manager_data = this.inquiries.find(i => parseInt(i.manager.id) === parseInt(sales_manager_id))
          if (manager_data) {
            await this.$refs.responseTimeKPIModal.openModal(manager_data, filter_by_grade || null)
          } else {
            await this.$router.push({query: {}})
          }
        } catch {
          await this.$router.push({query: {}})
        }
      } else if (sales_manager_id && kpi_modal === 'follow_up') {
        try {
          let manager_data = this.inquiries.find(i => parseInt(i.manager.id) === parseInt(sales_manager_id))
          if (manager_data) {
            await this.$refs.completionKPIModal.openModal(manager_data, filter_by_grade || null)
          } else {
            await this.$router.push({query: {}})
          }
        } catch {
          await this.$router.push({query: {}})
        }
      } else if (sales_manager_id && kpi_modal === 'new_leads') {
        try {
          let manager_data = this.inquiries.find(i => parseInt(i.manager.id) === parseInt(sales_manager_id))
          if (manager_data) {
            await this.$refs.leadsKPIModal.openModal(manager_data)
          } else {
            await this.$router.push({query: {}})
          }
        } catch {
          await this.$router.push({query: {}})
        }
      }

      if (filter_by_status) {
        try {
          let manager_data = this.inquiries.find(i => parseInt(i.manager.id) === parseInt(sales_manager_id))
          if (manager_data) {
            await this.$refs.inquiresByStatusModal.openModal(manager_data, this.active_tab, filter_by_status)
          } else {
            await this.$router.push({query: {}})
          }
        } catch {
          await this.$router.push({query: {}})
        }
      }

    },
    get_status_color(status) {
      switch (status) {
        case 'Pending':
          return 'warning';
        case 'Quoted':
          return 'secondary';
        case 'Success':
          return 'success';
        case 'Fail':
          return 'danger';
        default:
          return 'muted';
      }
    },
    get_overall_grade_color(grade) {
      switch (grade) {
        case 'A':
          return 'success';
        case 'B':
          return 'secondary';
        case 'C':
          return 'warning';
        case 'D':
          return 'danger';
        default:
          return 'muted';
      }
    },
    calculateOverallPerformance(manager) {
      let response_time_percentage = (manager.response_time.target * (this.INQUIRY_KPI.response_time.weight / 100))
      let completion_percentage = (manager.completion.target * (this.INQUIRY_KPI.completion.weight / 100))
      let conversion_percentage = (manager.conversion.target * (this.INQUIRY_KPI.conversion.weight / 100))
      let leads_percentage = (manager.leads.target * (this.INQUIRY_KPI.leads.weight / 100))

      return (response_time_percentage + completion_percentage + conversion_percentage + leads_percentage).toFixed(1)
    }
  },
  created() {
    this.getInquiries();
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getInquiries();
        }
      },
      deep: true
    },
    "$route.query.sales_manager_id": {
      async handler(newValue) {
        if (newValue) {
          await this.checkRouterQuery()
        }
      },
    },
    active_tab() {
      this.getInquiries();
    }
  },
};
</script>

<template>
  <ResponseTimeKPIModal ref="responseTimeKPIModal"/>
  <CompletionKPIModal
      ref="completionKPIModal"
  />
  <LeadsKPIModal ref="leadsKPIModal"/>
  <InquiresByStatusModal
      :date_from="date_from"
      :date_to="date_to"
      ref="inquiresByStatusModal"/>

  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <b-card-title class="mb-0">
        Inquiries by Managers
      </b-card-title>
      <div>
        <i v-b-tooltip.hover title="Sales KPI details" @click="showSalesKPIModal = true"
           class="ri-question-line fs-3 align-middle me-2 link-primary cursor-pointer"></i>
        <img src="@/assets/images/inquiry.webp" alt="Inquiry Icon" style="height: 20px; width: 20px; margin-right: 5px">
      </div>
    </b-card-header>
    <b-card-body class="mb-0 pb-0 pt-3">
      <ul class="nav nav-pills nav-custom nav-custom-light mb-3" role="tablist">
        <li @click="active_tab = 'all'" class="nav-item" :class="{
            'active': active_tab === 'all'
          }">
          <a class="nav-link" href="#inquiry-all" target="_self" data-bs-toggle="tab" role="tab"> All
          </a>
        </li>
        <li @click="active_tab = 'kpi'" class="nav-item">
          <a class="nav-link" :class="{
            'active': active_tab === 'kpi'
          }" href="#inquiry-kpi" target="_self" data-bs-toggle="tab" role="tab">
            KPI
          </a>
        </li>
      </ul>

      <b-modal v-model="showSalesKPIModal" hide-footer centered title="Sales KPI details" size="lg">
        <InquiryKPI/>
      </b-modal>

      <div class="table-responsive mt-3">
        <table class="table table-nowrap">
          <thead>
          <tr class="">
            <th scope="col">Manager</th>
            <th class="text-center" scope="col">Total</th>
            <th class="text-center" scope="col">Pending</th>
            <th class="text-center" scope="col">Quoted</th>
            <th class="text-center" scope="col">Success</th>
            <th class="text-center" scope="col">Fail</th>
            <template v-if="active_tab === 'kpi'">
              <th class="text-center" scope="col">Response Time KPI</th>
              <th class="text-center" scope="col">Follow-up KPI</th>
              <th class="text-center" scope="col">Conversion Rate KPI</th>
              <th class="text-center" scope="col">New Leads Generated</th>
              <th class="text-center" scope="col">Overall Performance</th>
            </template>
          </tr>
          </thead>
          <tbody>
          <tr v-if="is_loading" class="align-middle">
            <td :colspan="active_tab === 'all' ? 6: 8" class="py-3">
              <b-spinner class="fs-10 me-2 text-secondary" style="width: 15px; height: 15px"></b-spinner>
              Loading...
            </td>
          </tr>
          <template v-for="item in inquiries" :key="`item_${item}`">
            <tr class="align-middle">
              <th scope="row">
                <a class="fw-semibold" :class="{
                  'text-danger': !item.manager.username
                }">
                  {{
                    (item.manager.full_name || '').trim().length > 0 ? item.manager.full_name : item.manager.username || 'Unknown Manager'
                  }}
                </a>
              </th>
              <td class="text-center">
                <h5 @click="this.$refs.inquiresByStatusModal.openModal(item, active_tab)"
                    class="mb-0 cursor-pointer d-inline-block">
                  <span class="badge badge-soft-dark">
                  {{ item.total }}
                </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">

                <h5 class="cursor-pointer mb-0 d-inline-block" v-b-tooltip.hover title="Pending"
                    @click="this.$refs.inquiresByStatusModal.openModal(item, active_tab,  'pending')"
                >
                    <span class="badge badge-soft-warning">
                      {{
                        item.statuses.filter(i => i.label === 'Pending').length > 0
                            ? item.statuses.find(i => i.label === 'Pending').value
                            : 0
                      }}
                    </span>
                </h5>

              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0 d-inline-block" v-b-tooltip.hover title="Quoted"
                    @click="this.$refs.inquiresByStatusModal.openModal(item, active_tab,'quoted')"
                >
                    <span class="badge badge-soft-secondary">
                      {{
                        item.statuses.filter(i => i.label === 'Quoted').length > 0
                            ? item.statuses.find(i => i.label === 'Quoted').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0 d-inline-block" v-b-tooltip.hover title="Success"
                    @click="this.$refs.inquiresByStatusModal.openModal(item, active_tab, 'success')"
                >
                    <span class="badge badge-soft-success">
                      {{
                        item.statuses.filter(i => i.label === 'Success').length > 0
                            ? item.statuses.find(i => i.label === 'Success').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <td class="text-center" style="min-width: 50px">
                <h5 class="cursor-pointer mb-0 d-inline-block" v-b-tooltip.hover title="Fail"
                    @click="this.$refs.inquiresByStatusModal.openModal(item, active_tab, 'fail')"
                >
                    <span class="badge badge-soft-danger">
                      {{
                        item.statuses.filter(i => i.label === 'Fail').length > 0
                            ? item.statuses.find(i => i.label === 'Fail').value
                            : 0
                      }}
                    </span>
                </h5>
              </td>
              <template v-if="active_tab === 'kpi'">
                <td class="text-center">
                  <div class="text-start ms-3">
                    <h6 class="mt-0 mb-1">
                      <a v-b-tooltip.hover
                         :title="`(${item.response_time.details.A.count} * 3 + ${item.response_time.details.B.count} * 2 + ${item.response_time.details.C.count} * (-1)) / ${(item.response_time.details.A.count + item.response_time.details.B.count + item.response_time.details.C.count) * 3}`"
                         @click="this.$refs.responseTimeKPIModal.openModal(item)"
                         class="text-reset text-decoration-underline cursor-pointer">
                        {{ item.response_time.target }}%
                      </a>
                    </h6>
                    <div class="text-muted d-flex gap-1">
                      <span
                          v-b-tooltip.hover title="Number of inquires quoted within 60 hours"
                          @click="this.$refs.responseTimeKPIModal.openModal(item, 'A')"
                          class="badge border border-success text-success">
                        {{ item.response_time.details.A.count }}
                      </span>
                      <span
                          v-b-tooltip.hover title="Number of inquires quoted between 60 and 84 hours."
                          @click="this.$refs.responseTimeKPIModal.openModal(item, 'B')"
                          class="badge border border-warning text-warning">
                        {{ item.response_time.details.B.count }}
                      </span>
                      <span
                          v-b-tooltip.hover title="Number of inquires quoted after 84 hours"
                          @click="this.$refs.responseTimeKPIModal.openModal(item, 'C')"
                          class="badge border border-danger text-danger">
                        {{ item.response_time.details.C.count }}
                      </span>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  <div class="text-start ms-3">
                    <h6 class="mt-0 mb-1">
                      <a v-b-tooltip.hover
                         :title="`(${item.completion.details.A.count} * 3 + ${item.completion.details.B.count} * 2 + ${item.completion.details.C.count} * (-1)) / (${(item.completion.details.A.count + item.completion.details.B.count + item.completion.details.C.count) * 3})`"
                         @click="this.$refs.completionKPIModal.openModal(item)"
                         class="text-reset text-decoration-underline cursor-pointer">
                        {{ item.completion.target }}%
                      </a>
                    </h6>
                    <div class="text-muted d-flex gap-1">
                      <span
                          v-b-tooltip.hover title="Number of inquires completed within 5 days."
                          @click="this.$refs.completionKPIModal.openModal(item, 'A')"
                          class="badge border border-success text-success">
                        {{ item.completion.details.A.count }}
                      </span>
                      <span
                          v-b-tooltip.hover title="Number of inquires completed between 6 and 7 days."
                          @click="this.$refs.completionKPIModal.openModal(item, 'B')"
                          class="badge border border-warning text-warning">
                        {{ item.completion.details.B.count }}
                      </span>
                      <span
                          v-b-tooltip.hover title="Number of inquires completed after 7 days"
                          @click="this.$refs.completionKPIModal.openModal(item, 'C')"
                          class="badge border border-danger text-danger">
                        {{ item.completion.details.C.count }}
                      </span>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  <div class="text-start ms-3">
                    <h6 v-b-tooltip.hover
                        :title="`${item.manager.username} ${item.conversion.target === 100? 'successfully met' : 'did not meet'} the KPI requirement`"
                        class="mt-0 mb-1">
                      {{ item.conversion.target }}
                      <i class="mdi mdi-percent-outline"></i>
                    </h6>
                    <div class="text-muted d-flex align-items-center gap-1">
                      <i class="mdi mdi-information-outline"></i>
                      <div class="d-flex align-items-center">
                        <span v-b-tooltip.hover title="Successful inquiries count">{{
                            item.total_success_inquiries
                          }}</span>
                        <i class="mdi mdi-slash-forward"></i>
                        <span v-b-tooltip.hover title="Total inquiries (excluding pending)">{{
                            item.total - item.total_pending_inquiries
                          }}</span>
                        <i class="mdi mdi-approximately-equal"></i>
                        <span v-b-tooltip.hover title="Percentage of successful inquires compared to total inquiries.">{{
                            item.conversion.actual
                          }}</span>
                        <i class="mdi mdi-percent-outline"></i>
                      </div>
                    </div>
                  </div>
                </td>


                <td class="text-center">
                  <div class="text-start ms-3">
                    <h6 v-b-tooltip.hover
                        :title="`${item.manager.username} ${item.leads.target === 100? 'successfully met' : 'did not meet'} the KPI requirement`"
                        class="mt-0 mb-1 text-decoration-underline cursor-pointer"
                        @click="this.$refs.leadsKPIModal.openModal(item)"
                    >
                      {{ item.leads.target }}
                      <i class="mdi mdi-percent-outline"></i>
                    </h6>
                    <div class="text-muted">
                      <i class="mdi mdi-information-outline me-1"></i>
                      <span v-b-tooltip.hover
                            :title="`New leads ${item.manager.username} generated`">{{ item.leads.actual }}</span>
                      <i class="mdi mdi-slash-forward"></i>
                      <span v-b-tooltip.hover title="Required new leads to be generated">5</span>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  <!-- Menu Content -->
                  <BButtonGroup>
                    <b-dropdown size="sm" :variant="item.overall_percentage >= item.percentage_to_get_green_kpi ? 'success' : 'light'"
                                :text="`${item.overall_percentage}%`">
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Response Time:</b>
                          <span>
                            {{ item.response_time.target }} * {{ INQUIRY_KPI.response_time.weight / 100 }} = {{
                              parseFloat(item.response_time.target * (INQUIRY_KPI.response_time.weight / 100)).toFixed(1)
                            }}
                          </span>
                        </div>
                      </BDropdownItem>
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Follow-up:</b>
                          <span>
                            {{ item.completion.target }} * {{ INQUIRY_KPI.completion.weight / 100 }} = {{
                              parseFloat(item.completion.target * (INQUIRY_KPI.completion.weight / 100)).toFixed(1)
                            }}
                          </span>
                        </div>
                      </BDropdownItem>
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Conversion:</b>
                          <span>
                            {{ item.conversion.target }} * {{ INQUIRY_KPI.conversion.weight / 100 }} = {{
                              parseFloat(item.conversion.target * (INQUIRY_KPI.conversion.weight / 100)).toFixed(1)
                            }}
                          </span>
                        </div>
                      </BDropdownItem>
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Leads:</b>
                          <span>
                            {{ item.leads.target }} * {{
                              INQUIRY_KPI.leads.weight / 100
                            }} = {{ parseFloat(item.leads.target * (INQUIRY_KPI.leads.weight / 100)).toFixed(1) }}
                          </span>
                        </div>
                      </BDropdownItem>
                      <b-dropdown-divider></b-dropdown-divider>
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Target:</b>
                          <b>{{ item.percentage_to_get_green_kpi }}%</b>
                        </div>
                      </BDropdownItem>
                      <BDropdownItem>
                        <div class="d-flex justify-content-between gap-4">
                          <b>Overall:</b>
                          <b>{{ item.overall_percentage }}%</b>
                        </div>
                      </BDropdownItem>
                    </b-dropdown>
                  </BButtonGroup>
                </td>
              </template>
            </tr>
          </template>
          <template v-if="!is_loading">
            <tr v-if="inquiries.length === 0">
              <td :colspan="active_tab === 'all' ? 6: 11" class="py-3 text-danger">
                No inquiries for given period
              </td>
            </tr>
            <tr v-else class="bg-light">
              <th>
                TOTAL
              </th>
              <th class="text-center">
                {{ inquiries.reduce((sum, inquiry_item) => sum + parseInt(inquiry_item.total || 0), 0) }}
              </th>
              <th class="text-center">
                {{
                  inquiries.reduce((sum, inquiry_item) => {
                    const failStatus = inquiry_item.statuses.find(i => i.label === 'Pending');
                    const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                    return sum + failValue;
                  }, 0)
                }}
              </th>
              <th class="text-center">
                {{
                  inquiries.reduce((sum, inquiry_item) => {
                    const failStatus = inquiry_item.statuses.find(i => i.label === 'Quoted');
                    const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                    return sum + failValue;
                  }, 0)
                }}
              </th>
              <th class="text-center">
                {{
                  inquiries.reduce((sum, inquiry_item) => {
                    const failStatus = inquiry_item.statuses.find(i => i.label === 'Success');
                    const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                    return sum + failValue;
                  }, 0)
                }}
              </th>
              <th class="text-center">
                {{
                  inquiries.reduce((sum, inquiry_item) => {
                    const failStatus = inquiry_item.statuses.find(i => i.label === 'Fail');
                    const failValue = failStatus ? parseInt(failStatus.value, 10) || 0 : 0;
                    return sum + failValue;
                  }, 0)
                }}
              </th>
              <template v-if="active_tab === 'kpi'">
                <th>
                  <div v-b-tooltip.hover title="Average Response Time KPI percentage" class="ms-3">
                  <span>
                    {{
                      parseFloat(inquiries.map(i => i.response_time.target).reduce((a, b) => a + b, 0) / inquiries.length).toFixed(1)
                    }}
                  </span>
                    <i class="mdi mdi-percent-outline"></i>
                  </div>
                </th>
                <th>
                  <div v-b-tooltip.hover title="Average Follow-up KPI percentage" class="ms-3">
                  <span>
                    {{
                      parseFloat(inquiries.map(i => i.completion.target).reduce((a, b) => a + b, 0) / inquiries.length).toFixed(1)
                    }}
                  </span>
                    <i class="mdi mdi-percent-outline"></i>
                  </div>
                </th>
                <th>
                  <div v-b-tooltip.hover title="Average Conversion Rate KPI percentage" class="ms-3">
                  <span>
                    {{
                      parseFloat(inquiries.map(i => i.conversion.target).reduce((a, b) => a + b, 0) / inquiries.length).toFixed(1)
                    }}
                  </span>
                    <i class="mdi mdi-percent-outline"></i>
                  </div>
                </th>
                <th>
                  <div v-b-tooltip.hover title="Average New Leads KPI percentage" class="ms-3">
                  <span>
                    {{
                      parseFloat(inquiries.map(i => i.leads.target).reduce((a, b) => a + b, 0) / inquiries.length).toFixed(1)
                    }}
                  </span>
                    <i class="mdi mdi-percent-outline"></i>
                  </div>
                </th>
                <th class="text-center">
                  <div v-b-tooltip.hover title="Average Overall KPI percentage">
                  <span>
                    {{
                      parseFloat(inquiries.map(i => parseFloat(i.overall_percentage)).reduce((a, b) => a + b, 0) / inquiries.length).toFixed(1)
                    }}
                  </span>
                    <i class="mdi mdi-percent-outline"></i>
                  </div>
                </th>
              </template>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>
</template>

